/**
 * Created by zeref on 11/11/2016.
 */
import { isPlatformBrowser } from '@angular/common';
import { ElementRef, Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Request } from 'express';
import { Account, Role } from '../../models/account.model';
import { Campaign } from '../../models/campaign.model';
import { REQUEST } from '../../models/server.tokens';
import { User } from '../../models/user.model';
import { WindowRef } from '../../shared/services/window.service';
import { userInitialState } from '../../store/reducers/user.reducer';

@Injectable()
export class HelpersService {
    static makeRequestParams(params, game?) {
        const requestParams = { ...(game && { game }) };

        const flattenParams = (obj, parentKey = '') => {
            const flatParams = {};

            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const newKey = parentKey ? `${parentKey}[${key}]` : key;
                    const paramValue = obj[key];

                    if (typeof paramValue === 'object' && !Array.isArray(paramValue)) {
                        Object.assign(flatParams, flattenParams(paramValue, newKey));
                    } else {
                        flatParams[newKey] = paramValue;
                    }
                }
            }

            return flatParams;
        };

        if (params) {
            const flatParams = flattenParams(params);

            for (const key in flatParams) {
                if (flatParams.hasOwnProperty(key)) {
                    let paramValue = flatParams[key];

                    if (typeof paramValue === 'object' && !Array.isArray(paramValue)) {
                        paramValue =
                            Object.keys(paramValue)
                                .filter(paramValueKey => paramValue[paramValueKey])
                                .join(',') || 'all';
                    } else if (Array.isArray(paramValue)) {
                        paramValue = paramValue.join(',');
                    }

                    requestParams[key] = paramValue;
                }
            }
        }

        return requestParams;
    }

    public static handleResponseError(res: Response | any) {
        let err: any;
        try {
            if (res && res.error && typeof res.error === 'string') {
                res.error = JSON.parse(res.error);
            }
        } catch (err) {
            console.warn('Could not parse error to JSON.');
        }

        if (res.error) {
            if (res.error.result && typeof res.error.result.error === 'object') {
                err =
                    Object.keys(res.error.result.error).length === 0
                        ? res.error.result
                        : res.error.result.error;
            } else {
                err = res.error;
            }
        } else {
            err = { message: res.message ? res.message : res.toString() };
        }

        // message will be an object { reason: string } if the error comes from a microservice communication misfire
        if (err.message && err.message.reason && typeof err.message.reason === 'string') {
            err.message = err.message.reason;
        }
        if (res.headers) {
            err.headers = res.headers;
        }

        return err;
    }

    constructor(
        private windowRef: WindowRef,
        private sanitizer: DomSanitizer,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Optional() @Inject(REQUEST) private request: Request
    ) {}

    /**
     * Decimal adjustment of a number.
     *
     * @param   {String}    type    The type of adjustment.
     * @param   {Number}    value   The number.
     * @param   {Integer}   exp     The exponent (the 10 logarithm of the adjustment base).
     * @returns {Number}            The adjusted value.
     */
    private decimalAdjust(type: string, value: number | string | string[], exp: number) {
        // If the exp is undefined or zero...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // If the value is not a number or the exp is not an integer...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        // Shift
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));
        // Shift back
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
    }

    public isMobileDevice(): boolean {
        return (
            isPlatformBrowser(this.platformId) &&
            !!(
                navigator.userAgent.match(/Android/i) ||
                navigator.userAgent.match(/webOS/i) ||
                navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/iPod/i) ||
                navigator.userAgent.match(/BlackBerry/i) ||
                navigator.userAgent.match(/Windows Phone/i)
            )
        );
    }

    public isLaptopDevice(): boolean {
        const windowWidth = this.windowRef.nativeWindow.innerWidth;

        return windowWidth <= 1366 && !this.isMobileDevice();
    }

    public isOrientationLandscape() {
        return this.windowRef.nativeWindow.matchMedia('(orientation: landscape)').matches;
    }

    public getCurrentUrl() {
        if (isPlatformBrowser(this.platformId)) {
            return this.windowRef.nativeWindow.location.href;
        } else {
            return this.request.protocol + '://' + this.request.get('host') + this.request.originalUrl;
        }
    }

    public getCurrentFullPath(): string {
        // N.B. this function is not taking into account the port number.
        // Read more: https://developer.mozilla.org/en-US/docs/Web/API/Location
        const location = this.windowRef.nativeWindow.location;
        const url = location.href;
        const origin = location.origin; // e.g. https://www.gameleap.com
        return url.replace(origin, ''); // will get the full path with query params and hash
    }

    public getDateString(date: Date): string {
        // Format MM/DD/YYYY
        return (
            ('0' + (date.getMonth() + 1)).slice(-2) +
            '/' +
            ('0' + date.getDate()).slice(-2) +
            '/' +
            date.getFullYear()
        );
    }

    public redirectToExternalUrl(url: string) {
        this.windowRef.nativeWindow.location.href = url;
    }

    public scrollToTop(element?: ElementRef | any) {
        const scrollElement = element
            ? element.nativeElement || element
            : this.windowRef.nativeWindow;
        scrollElement.scrollTo({ top: 0, behavior: 'smooth' });
    }

    public shuffleArray(array) {
        const arrCopy = [...array];
        let currentIndex = arrCopy.length;
        let temporaryValue;
        let randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = arrCopy[currentIndex];
            arrCopy[currentIndex] = arrCopy[randomIndex];
            arrCopy[randomIndex] = temporaryValue;
        }

        return arrCopy;
    }

    public isBrowserChrome() {
        // @ts-ignore
        if (!this.windowRef.nativeWindow.chrome) {
            return false;
        }

        // @ts-ignore
        return this.windowRef.nativeWindow.chrome && this.windowRef.nativeWindow.chrome.webstore;
    }

    public round10(value: number, exp: number) {
        return this.decimalAdjust('round', value, exp);
    }

    public floor10(value: number, exp: number) {
        return this.decimalAdjust('floor', value, exp);
    }

    public ceil10(value: number, exp: number) {
        return this.decimalAdjust('ceil', value, exp);
    }

    /**
     * Used to format the user data prior to saving it in the store.
     * @param account | null
     */
    public formatUserData(account: Account | null): User {
        if (!account) {
            return userInitialState;
        }

        const roles = account.roles;

        return {
            account: account,
            isLoggedIn: true,
            isPremium: roles.includes(Role.PREMIUM) || roles.includes(Role.TRIAL),
            isAdmin: roles.includes(Role.ADMIN),
            isManager: roles.includes(Role.ADMIN) || roles.includes(Role.MANAGER),
            isWriter:
                roles.includes(Role.WRITER) ||
                roles.includes(Role.WRITER_INTERN) ||
                roles.includes(Role.WRITER_EDITOR),
            isTrial: roles.includes(Role.TRIAL),
            isFree: roles.includes(Role.FREE),
            isPlayer: roles.includes(Role.PLAYER),
            isInfluencer: roles.includes(Role.INFLUENCER),
            roles
        };
    }

    public isVP9Supported() {
        if (!this.windowRef.nativeWindow.MediaSource) {
            return false;
        }

        const vp9MimeType = 'video/webm; codecs="vp9"';

        return this.windowRef.nativeWindow.MediaSource.isTypeSupported(vp9MimeType);
    }

    public processModalHTML(content: string | undefined | null, entity?: Campaign | any): SafeHtml {
        if (content) {
            const regexp = new RegExp(/{(.*?)}+/, 'g');
            let regexpResult = regexp.exec(content);

            if (regexpResult) {
                while (regexpResult != null) {
                    const interpolationTarget = regexpResult[0];
                    const variableName = regexpResult[1];
                    const properties: string[] = variableName.split('.');
                    const replacementValue = properties.reduce((acc, prop) => acc[prop], entity);
                    content = content.replace(interpolationTarget, replacementValue);

                    // get the following match, if any, will return null if none
                    regexpResult = regexp.exec(content);
                }
            }
        }

        return this.sanitizer.bypassSecurityTrustHtml(content as string);
    }

    public traverseDOM(element, direction, condition) {
        if (direction === 'up') {
            // Check parent elements
            let parent = element.parentElement;
            while (parent) {
                if (condition(parent)) {
                    return parent;
                }
                parent = parent.parentElement;
            }
        } else if (direction === 'down') {
            // Check child elements
            let nodes = Array.from(element.getElementsByTagName('*'));
            for (let node of nodes) {
                if (condition(node)) {
                    return node;
                }
            }
        }
        return null;
    }
}

